import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from "axios";
import { getToken } from "../services/LocalStorageSrv";
import { removeToken } from "../services/LocalStorageSrv";
import { SlCalender } from "react-icons/sl";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa6";
import Logout from "../Pages/Logout";
const Navbar = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const result = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const lastId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const [bookingStatus, setBookingStatus] = useState('');
  // console.log('Last ID from URL:', lastId);
  const nvg = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuProfile = () => {
    setIsOpen(!isOpen);
  };

  const [sidemenu, setSidemenu] = useState(false);

  const toggleSidemenu = () => {
    setSidemenu(!sidemenu);
  };


  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const [dropdownOpen1, setDropdownOpen1] = useState(false);

  const toggleDropdown1 = () => {
    setDropdownOpen1(!dropdownOpen1);
  };
  const [dropdownOpen2, setDropdownOpen2] = useState(false);

  const toggleDropdown2 = () => {
    setDropdownOpen2(!dropdownOpen2);
  };

  useEffect(() => {
    if (result === '/edit-appointment' && lastId !== '') {
      const fetchBookingStatus = async () => {
        try {
          const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.edit_appointment`,
          {"appointment_id":lastId}, // Empty body
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
    
        // console.log("booking d",response.data.data.appointment_list[0].status)
        setBookingStatus(response.data.data.appointment_list[0].status)
          // setProduct(response.data); // Store the product data in state
          setLoading(false); // Set loading to false once data is fetched
        } catch (err) {
          // setError(err.message); // Handle any errors
          setLoading(false);
        }
      };
      
  
      fetchBookingStatus();
    }
  }, [lastId]); 
  

  const renderHeader = () => {
    if (location.pathname === '/doctors') {
      return <h2 className="text-xl font-bold text-gray-800">Consultant</h2>;
    }else if (location.pathname === '/new-doctor') {
      return <h2 className="text-xl font-bold text-gray-800">New Consultant</h2>;
    } else if (location.pathname === '/patient') {
      return <h2 className="text-xl font-bold text-gray-800">Patients</h2>;
    }else if (location.pathname === '/new-patient') {
      return <h2 className="text-xl font-bold text-gray-800">New Patients</h2>;
    } else if (location.pathname === '/patientencounters' || location.pathname === '/new-patientencounter') {
      return <h2 className="text-xl font-bold text-gray-800">Patient Encounter</h2>;
    }else if (location.pathname === '/new-appointment') { 
      return <h2 className="text-xl font-bold text-gray-800">New Billing</h2>;
    }else if (result === '/edit-appointment') {
      return <h2 className="text-xl font-bold text-gray-800 flex items-center">
      {lastId} 
      <div className="w-auto h-[17px] rounded-[13px] px-[10px] py-[3px] gap-[10px] bg-[#DC3545] bg-opacity-20 ml-2 font-mulish font-semibold text-[9px] leading-[11.3px] text-[#DC3545] flex items-center justify-center mt-1">
        {bookingStatus ? bookingStatus : ''}
      </div>
    </h2>
    ;
    }else if (location.pathname === '/payment') {
      return <h2 className="text-xl font-bold text-gray-800">Payment</h2>;
    }else if (location.pathname === '/new-schedule') {
      return <h2 className="text-xl font-bold text-gray-800">New Practitioner Schedule</h2>;
    }else if (location.pathname === '/department') {
      return <h2 className="text-xl font-bold text-gray-800">Department</h2>;
    }else if (location.pathname === '/new-department') {
      return <h2 className="text-xl font-bold text-gray-800">New Department</h2>;
    }else{
      return <h2 className="text-xl font-bold text-gray-800">Dashboard</h2>;
    }
  };
  return (

    <div>

      <>
        {location.pathname === '/appointment' ? (
          <div className="p-4 md:flex md:flex-row  w-full gap-3 h-auto flex flex-col-reverse md:h-30">
            {/* md:border-r-[2px] border-gray-500  */}
            <div className="md:flex md:w-auto w-full gap-8  h-auto ">
              <div className="md:w-1/2 md:mb-0 mb-2 h-auto overflow-auto">
              <div className="text-[#fff] text-left w-[214px] h-[23px] rounded-[3px] px-[10px] pr-[20px] py-[4px] bg-[#823455] md:w-auto">
                  <p className="font-mulish font-semibold text-[12px] leading-[15.06px] tracking-[0.5px] w-[183px] h-[15px]">Patient Count</p>
                </div>
                {/* <div className="flex justify-start p-1 px-3 mb-2 text-white bg-[#823455] rounded-md">
                  <p></p>
                </div> */}
                <div className="flex mb-1 mt-2">
                  <div className="w-[74%] flex justify-start h-[13px] border-r-2 border-gray-500 font-mulish font-normal text-[12px] leading-[15.06px] text-[#333333]">
                    Total Visits
                  </div>
                  <div className="w-[25%] flex justify-end h-[13px] font-bold text-[#0284C7] text-[12px] leading-[15.06px]">
                    5000
                  </div>
                </div>
                <div className="flex mb-1 mt-2">
                  <div className="w-[74%] flex justify-start h-[13px] border-r-2 border-gray-500 font-mulish font-normal text-[12px] leading-[15.06px] text-[#333333]">
                    Total Visits Complete
                  </div>
                  <div className="w-[25%] flex justify-end h-[13px] font-bold text-[#16A34A] text-[12px] leading-[15.06px]">
                    2000
                  </div>
                </div>
                <div className="flex mb-1 mt-2">
                  <div className="w-[74%] flex justify-start h-[13px] border-r-2 border-gray-500 font-mulish font-normal text-[12px] leading-[15.06px] text-[#333333]">
                    Total Visits Complete
                  </div>
                  <div className="w-[25%] flex justify-end h-[13px] font-bold text-[#D97706] text-[12px] leading-[15.06px]">
                    3000
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 h-auto md:mr-9">
                <div className="text-[#fff] text-left w-[214px] h-[23px] rounded-[3px] px-[10px] pr-[20px] py-[4px] bg-[#823455]">
                  <p className="font-mulish font-semibold text-[12px] leading-[15.06px] tracking-[0.5px] w-[183px] h-[15px]">Consultant Vise Patient Count</p>
                </div>
                <div className="flex mb-1 mt-2">
                  <div className="w-[74%] flex justify-start h-[13px] border-r-2 border-gray-500 font-mulish font-normal text-[12px] leading-[15.06px] text-[#333333]">
                    Total Visits
                  </div>
                  <div className="w-[25%] flex justify-end h-[13px] font-bold text-[#0284C7] text-[12px] leading-[15.06px]">
                    5000
                  </div>
                </div>
                <div className="flex mb-1 mt-2">
                  <div className="w-[74%] flex justify-start h-[13px] border-r-2 border-gray-500 font-mulish font-normal text-[12px] leading-[15.06px] text-[#333333]">
                    Total Visits Complete
                  </div>
                  <div className="w-[25%] flex justify-end h-[13px] font-bold text-[#16A34A] text-[12px] leading-[15.06px]">
                    2000
                  </div>
                </div>
                <div className="flex mb-1 mt-2">
                  <div className="w-[74%] flex justify-start h-[13px] border-r-2 border-gray-500 font-mulish font-normal text-[12px] leading-[15.06px] text-[#333333]">
                    Total Visits Complete
                  </div>
                  <div className="w-[25%] flex justify-end h-[13px] font-bold text-[#D97706] text-[12px] leading-[15.06px]">
                    3000
                  </div>
                </div>
              </div>
            </div>
            <div className=" md:flex md:flex-row  md:w-[55%] md:gap-5 w-full  h-auto flex flex-col-reverse ">

              <div className="w-full  md:flex md:flex-col gap-4 flex md:w-1/2  ">
                <div className="text-white bg-[#075985] md:ml-7 md:mb-4 hover:bg-[#075985] flex justify-start focus:outline-none h-[26px] font-medium rounded-sm text-sm px-5 py-2.5 text-center w-32">
                  <div className="flex gap-2 items-center cursor-pointer" onClick={toggleDropdown}
>
                    <SlCalender />


                    <span className="text-white font-[15px] ">Today</span>
                    <IoIosArrowDown />

                  </div>
                  {dropdownOpen && (
                  <div id="dropdown" className="z-10 absolute mt-4 bg-white divide-y divide-gray-100 rounded-lg shadow w-[130px] dark:bg-gray-700 ml-[-20px]" >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Earnings
                        </a>
                      </li>
                      <li>
                      <Logout />
                        {/* <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Sign out
                        </a> */}
                      </li>
                    </ul>
                  </div>
                )}
                </div>
                {/* border-b-2 border-gray-500 */}
                <div className="flex justify-start items-center md:ml-7">
                  <div>
                    <IoSearchOutline />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className=""
                    placeholder="   Type to search..."
                    required
                  />

                </div>
              </div>
             
              <div className="w-full md:w-1/2 flex items-center justify-end gap-8 mb-2">
  
  <div className="flex items-center gap-4 cursor-pointer" onClick={toggleDropdown2}>
    <div className="w-12 h-12 flex items-center justify-center rounded-full text-white font-bold">
    <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/profile_pic.png`} className="" alt="profile" />
    </div>
    <div className="flex flex-col items-start">
      <span className="font-mulish font-bold text-[16px] leading-[20.08px] text-[#1C2434]">Dr. Sherman Khan</span>
      <span className="font-mulish font-normal text-[12px] leading-[14px] text-[#637381]">Neurologist</span>
    </div>
    <div className="flex  md:mt-1 justify-start items-start cursor-pointer text-[#1C2434] mb-[13px]" onClick={toggleMenuProfile}
>
                    <IoIosArrowDown />
                  </div>
    {dropdownOpen2 && (
      <div id="dropdown" className="absolute top-14 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 z-20 mt-[20px] ml-[58px]">
        <ul className="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
          <li>
            <a href="#" className="block px-4 py-2 hover:bg-gray-100">Dashboard</a>
          </li>
          <li>
            <a href="#" className="block px-4 py-2 hover:bg-gray-100">Settings</a>
          </li>
          <li>
            <a href="#" className="block px-4 py-2 hover:bg-gray-100">Earnings</a>
          </li>
          <li>
            <Logout />
          </li>
        </ul>
      </div>
    )}
     <div className="relative flex items-center">
    <div className="bg-[#EFF4FB] w-10 h-10 flex items-center justify-center rounded-full text-[#64748B]">
      <FaRegBell />
    </div>
    <div className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></div>
  </div>
  </div>
  
 

</div>




            </div>




            

               
                <div className="p-2 rounded-full hover:bg-gray-200 cursor-pointer md:hidden" onClick={toggleSidemenu}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                    <line x1="4" y1="6" x2="20" y2="6"></line>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                    <line x1="4" y1="18" x2="20" y2="18"></line>
                  </svg>
                </div>
              

            

          </div>
        )





          : (
            <div className="p-4 md:flex md:flex-row  w-full gap-3 h-auto flex flex-col-reverse md:h-20">
               
              <div className="flex items-center w-2/3">
              {renderHeader()}
              </div>
             
              {/* border-b-2 border-gray-500 */}
              {(location.pathname === '/new-patient' || location.pathname === `/edit-patient/${lastId}`) || (location.pathname === '/new-appointment' || location.pathname === `/edit-appointment/${lastId}`) || (location.pathname === '/new-doctor' || location.pathname === `/edit-doctor/${lastId}`) ? (    
                ''
              ):(  
              <div className="flex justify-start items-center md:ml-7  ">
              
                  <div>
                    <IoSearchOutline />
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className=""
                    placeholder="   Type to search..."
                    required
                  />

                </div>
              )}
                <div className="w-full md:w-1/2 md:mb-0 mb-2 flex justify-end   items-center gap-4">
                <div className="relative inline-block">
                <div className="bg-[#EFF4FB] w-10 text-[#64748B] h-10 flex items-center justify-center rounded-full">
                    <FaRegBell />
                  </div>
                  <div className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></div>
                </div>
               
                <div className="flex  gap-2 cursor-pointer" onClick={toggleDropdown2}>

                  <div>
                  <span class="w-[60px] h-[20px] font-mulish font-normal text-[12px] leading-[20px] text-right text-[#212B36]">
                  Dr Sherman Khan
                </span>

                  
                    {dropdownOpen2 && (
                  <div id="dropdown" className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 ml-[29px] mt-[23px]" >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Earnings
                        </a>
                      </li>
                      <li>
                      <Logout />

                        {/* <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          Sign out
                        </a> */}
                      </li>
                    </ul>
                  </div>
                )}
                    <div className="flex items-start justify-start">
                    <span class="w-[57px] h-[14px] font-mulish font-normal text-[10px] leading-[14px] text-right text-[#637381]">
                    Neurologist
                   </span>

                      {/* <p className="text-10px"></p> */}


                    </div>

                  </div>
                  <div className="w-12 h-12 flex items-center justify-center rounded-full">
                  <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/profile_pic.png`} className="" alt="profile" />
                </div>
                  <div className="mt-[14px] flex  md:mt-4 justify-start items-start cursor-pointer" onClick={toggleMenuProfile}
>
                    <IoIosArrowDown />
                  </div>
                  
                  

                </div>
                



              </div>
              {/* <div className="flex items-center w-2/3"> */}

               
                <div className="p-2 rounded-full hover:bg-gray-200 cursor-pointer md:hidden" onClick={toggleSidemenu}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                    <line x1="4" y1="6" x2="20" y2="6"></line>
                    <line x1="4" y1="12" x2="20" y2="12"></line>
                    <line x1="4" y1="18" x2="20" y2="18"></line>
                  </svg>
                </div>
              

              {/* </div> */}
             

            </div>
          )}
      </>


      <div onClick={toggleSidemenu} className={`fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 ${sidemenu ? 'opacity-75 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} ></div>
      {/* Small Screen Menu */}
      <div className={`fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-white transform ease-in-out duration-300 ${sidemenu ? 'translate-x-0' : '-translate-x-full'}`} style={{ background: 'linear-gradient(180deg, #FF8D5C 0%, #823455 48%, #270B79 100%)' }}>
        {/* Brand Logo / Name */}
        <div className="flex items-center px-6 py-3 h-16">
          <div className="text-2xl font-bold tracking-tight text-gray-800"><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`} className="" alt="logo" /></div>
        </div>
        {/* @end Brand Logo / Name */}

        <div className="px-4 py-2 flex-1 h-0 overflow-y-auto">
          <ul>
            <li>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  nvg("/dashboard");
                }}
                className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname === "/dashboard" ? "bg-[#FFFFFF2B]" : ""}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <rect x="4" y="4" width="6" height="6" rx="1"></rect>
                  <rect x="14" y="4" width="6" height="6" rx="1"></rect>
                  <rect x="4" y="14" width="6" height="6" rx="1"></rect>
                  <rect x="14" y="14" width="6" height="6" rx="1"></rect>
                </svg>
                Dashboard
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => {
                nvg("/patient");
              }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname == "/patient" || location.pathname == "/new-patient" || location.pathname.startsWith("/edit-patient/") ? "bg-[#FFFFFF2B]" : location.pathname == "/new-patient" ? "bg-[#FFFFFF2B]" : ""}`}>

                <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4c.267 0 .529 .026 .781 .076"></path>
                  <path d="M19 16l-2 3h4l-2 3"></path>
                </svg>
                Patient

              </a>
            </li>

            <li>
              <a href="javascript:void(0);"
                onClick={() => {
                  nvg("/doctors");
                }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname == "/doctors" || location.pathname == "/new-doctor" || location.pathname.startsWith("/edit-doctor/") ? "bg-[#FFFFFF2B]" : location.pathname == "/new-doctor" ? "bg-[#FFFFFF2B]" : ""}`}>
               <svg xmlns="http://www.w3.org/2000/svg" className="mr-4"  width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 8a2.993 2.993 0 0 0-1 5.816V15.5a4.5 4.5 0 0 1-9 0v-.59A6.004 6.004 0 0 0 14 9V3a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h1v5a4 4 0 0 1-8 0V4h1a1 1 0 0 0 0-2H3a1 1 0 0 0-1 1v6a6.004 6.004 0 0 0 5 5.91v.59a6.5 6.5 0 0 0 13 0v-1.684A2.993 2.993 0 0 0 19 8m0 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1"></path></svg>
               Consultant
              </a>
            </li>
            <li>
              <a href="javascript:void(0);"
                onClick={() => {
                  nvg("/appointment");
                }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname === "/appointment" || location.pathname === "/department" || location.pathname === "/new-department" || location.pathname.startsWith("/edit-appointment/") ? "bg-[#FFFFFF2B]" : location.pathname === "/new-appointment" ? "bg-[#FFFFFF2B]" : ""}`}>
               <svg xmlns="http://www.w3.org/2000/svg" className="mr-4"  width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15 4h3v15H2V4h3V3c0-.41.15-.76.44-1.06c.29-.29.65-.44 1.06-.44s.77.15 1.06.44c.29.3.44.65.44 1.06v1h4V3c0-.41.15-.76.44-1.06c.29-.29.65-.44 1.06-.44s.77.15 1.06.44c.29.3.44.65.44 1.06zM6 3v2.5a.49.49 0 0 0 .5.5a.49.49 0 0 0 .5-.5V3c0-.14-.05-.26-.15-.35c-.09-.1-.21-.15-.35-.15s-.26.05-.35.15c-.1.09-.15.21-.15.35m7 0v2.5c0 .14.05.26.14.36c.1.09.22.14.36.14s.26-.05.36-.14c.09-.1.14-.22.14-.36V3a.49.49 0 0 0-.5-.5a.49.49 0 0 0-.5.5m4 15V8H3v10zM7 9v2H5V9zm2 0h2v2H9zm4 2V9h2v2zm-6 1v2H5v-2zm2 0h2v2H9zm4 2v-2h2v2zm-6 1v2H5v-2zm4 2H9v-2h2zm4 0h-2v-2h2z"></path></svg>
                Billing
              </a>
            </li>
            <li>
              <a href="javascript:void(0);"
                onClick={() => {
                  nvg("/patientencounters");
                }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname == "/patientencounters" ? "bg-[#FFFFFF2B]" : location.pathname == "/new-patientencounter" ? "bg-[#FFFFFF2B]" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21zM8 8h8v2a4 4 0 0 1-4 4a4 4 0 0 1-4-4zm0-1l.41-4.1a1 1 0 0 1 1-.9h5.19c.51 0 .94.39.99.9L16 7zm4-4h-1v1h-1v1h1v1h1V5h1V4h-1z"></path></svg>
                Patient Encounter
              </a>
            </li>
            <li>
              <a href="javascript:void(0);"
                onClick={() => {
                  nvg("/payment");
                }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname === "/payment" ? "bg-[#FFFFFF2B]" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                  <polyline points="14 3 14 8 19 8"></polyline>
                  <path d="M17 21H7a2 2 0 0 1 -2 -2V5a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                  <line x1="9" y1="9" x2="10" y2="9"></line>
                  <line x1="9" y1="13" x2="15" y2="13"></line>
                  <line x1="9" y1="17" x2="15" y2="17"></line>
                </svg>
                Payments
              </a>
            </li>
            {/* Add other list items similarly */}
          </ul>
        </div>

      </div>
      {/* @end Small Screen Menu */}

    </div>

  );
};

export default Navbar;
