import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom';

const SidebarWeb = () => {
  const location = useLocation();
  const result = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const lastId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const nvg = useNavigate();
  return (
    // overflow-y-auto
    <div className="bg-white w-64 min-h-screen hidden md:block shadow relative z-30" style={{background: 'linear-gradient(180deg, #FF8D5C 0%, #823455 48%, #270B79 100%)'}}>

      {/* Brand Logo / Name */}
      <div className="flex items-center px-6 py-3 h-16">
        <div className="text-2xl font-bold tracking-tight text-gray-800"><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`} className="" alt="logo" /></div>
      </div>
      {/* @end Brand Logo / Name */}

      <div className="px-4 py-2">
        <ul>
          <li>
          <a
  href="javascript:void(0);"
  onClick={() => {
    nvg("/dashboard");
  }}
  className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname === "/dashboard" ? "bg-[#FFFFFF2B]" : ""}`}
>
              <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                <rect x="4" y="4" width="6" height="6" rx="1"></rect>
                <rect x="14" y="4" width="6" height="6" rx="1"></rect>
                <rect x="4" y="14" width="6" height="6" rx="1"></rect>
                <rect x="14" y="14" width="6" height="6" rx="1"></rect>
              </svg>
               Dashboard
            </a>
          </li>
          <li>
						<a href="javascript:void(0);"  onClick={() => {
                                      nvg("/patient");
                                    }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname == "/patient" || location.pathname == "/new-patient" || location.pathname.startsWith("/edit-patient/") ? "bg-[#FFFFFF2B]" : location.pathname == "/new-patient" ? "bg-[#FFFFFF2B]" : ""}`}>
							
              <svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
			<path d="M6 21v-2a4 4 0 0 1 4 -4h4c.267 0 .529 .026 .781 .076"></path>
			<path d="M19 16l-2 3h4l-2 3"></path>
		 </svg>
      Patient 
							
						</a>
					</li>

					<li>
						<a href="javascript:void(0);"
                                    onClick={() => {
                                      nvg("/doctors");
                                    }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname == "/doctors" || location.pathname == "/new-doctor" || location.pathname.startsWith("/edit-doctor/") ? "bg-[#FFFFFF2B]" : location.pathname == "/new-doctor" ? "bg-[#FFFFFF2B]" : ""}`}>
           
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-4"  width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 8a2.993 2.993 0 0 0-1 5.816V15.5a4.5 4.5 0 0 1-9 0v-.59A6.004 6.004 0 0 0 14 9V3a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h1v5a4 4 0 0 1-8 0V4h1a1 1 0 0 0 0-2H3a1 1 0 0 0-1 1v6a6.004 6.004 0 0 0 5 5.91v.59a6.5 6.5 0 0 0 13 0v-1.684A2.993 2.993 0 0 0 19 8m0 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1"></path></svg>
            Consultant
						</a>
					</li>
          <li>
						<a href="javascript:void(0);"
                                    onClick={() => {
                                      nvg("/appointment");
                                    }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname === "/appointment" || location.pathname === "/department" || location.pathname === "/new-department" || location.pathname.startsWith("/edit-appointment/") ? "bg-[#FFFFFF2B]" : location.pathname === "/new-appointment" ? "bg-[#FFFFFF2B]" : ""}`}
>
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-4"  width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15 4h3v15H2V4h3V3c0-.41.15-.76.44-1.06c.29-.29.65-.44 1.06-.44s.77.15 1.06.44c.29.3.44.65.44 1.06v1h4V3c0-.41.15-.76.44-1.06c.29-.29.65-.44 1.06-.44s.77.15 1.06.44c.29.3.44.65.44 1.06zM6 3v2.5a.49.49 0 0 0 .5.5a.49.49 0 0 0 .5-.5V3c0-.14-.05-.26-.15-.35c-.09-.1-.21-.15-.35-.15s-.26.05-.35.15c-.1.09-.15.21-.15.35m7 0v2.5c0 .14.05.26.14.36c.1.09.22.14.36.14s.26-.05.36-.14c.09-.1.14-.22.14-.36V3a.49.49 0 0 0-.5-.5a.49.49 0 0 0-.5.5m4 15V8H3v10zM7 9v2H5V9zm2 0h2v2H9zm4 2V9h2v2zm-6 1v2H5v-2zm2 0h2v2H9zm4 2v-2h2v2zm-6 1v2H5v-2zm4 2H9v-2h2zm4 0h-2v-2h2z"></path></svg>
          
							Billing
						</a>
					</li>
          <li>
						<a href="javascript:void(0);"
                                    onClick={() => {
                                      nvg("/patientencounters");
                                    }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname == "/patientencounters" ? "bg-[#FFFFFF2B]" : location.pathname == "/new-patientencounter" ? "bg-[#FFFFFF2B]" : ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg"  className="mr-4"  width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21zM8 8h8v2a4 4 0 0 1-4 4a4 4 0 0 1-4-4zm0-1l.41-4.1a1 1 0 0 1 1-.9h5.19c.51 0 .94.39.99.9L16 7zm4-4h-1v1h-1v1h1v1h1V5h1V4h-1z"></path></svg>
            Patient Encounter
						</a>
					</li>
          <li>
						<a href="javascript:void(0);"
                                    onClick={() => {
                                      nvg("/payment");
                                    }} className={`font-mulish font-small text-base leading-6 mb-1 px-2 py-2 rounded-lg flex items-center font-small hover:text-blue-600 hover:bg-gray-200 text-[#fff] ${location.pathname === "/payment" ? "bg-[#FFFFFF2B]" : ""}`}>
							<svg xmlns="http://www.w3.org/2000/svg" className="mr-4" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<rect x="0" y="0" width="24" height="24" stroke="none"></rect>
								<polyline points="14 3 14 8 19 8"></polyline>
								<path d="M17 21H7a2 2 0 0 1 -2 -2V5a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
								<line x1="9" y1="9" x2="10" y2="9"></line>
								<line x1="9" y1="13" x2="15" y2="13"></line>
								<line x1="9" y1="17" x2="15" y2="17"></line>
							</svg>
							Payments
						</a>
					</li>
          {/* Add other list items similarly */}
        </ul>
      </div>

      {/* <div className="bg-orange-200 mb-10 p-6 rounded-lg mt-16">
        <h2 className="text-gray-800 text-lg leading-tight">Try <strong className="font-bold">Dashing Admin.</strong></h2>
        <p className="text-gray-800 text-lg mb-4 leading-tight">Premium for free!</p>
        <button className="shadow text-center w-full block bg-blue-500 hover:bg-blue-600 focus:outline-none focus:shadow-outline text-white font-semibold py-2 px-4 rounded-lg">
          30 Days Free Trail	  
        </button>
      </div> */}
       <div className="flex items-center px-6 py-3 h-16" style={{marginTop:'330px'}}>
        <div className="text-white-800 text-left mb-[40px]">
              <span style={{ fontSize: '14px', textAlign: 'left' }} className="text-white-2 text-[#ffffffab]">Powered By</span>
              <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/Stackerbee-White-logo.png`} style={{ width: '172.5px', height: '40px' }} alt="logo" />
          </div>
        </div>
    </div>
  
   
  
  );
};

export default SidebarWeb;
