import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Layout from '../components/Layout';
import AddAppointmentModal from '../Pages/AddAppointmentModal';
import AddIncomeAccountModal from '../Pages/AddIncomeAccountModal';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import Select from "react-select";
const NewDoctor = ({}) => {
   
	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
	  setActiveTab(tab);
	};

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [isOpen1, setIsOpen1] = useState(false);

  const openModal1 = () => {
    setIsOpen1(true);
  };

  const closeModal1 = () => {
    setIsOpen1(false);
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [totalDoctors, setTotalDoctor] = useState(0);
  const [series, setseries] = useState("");
  const [medicalDepartmentDetails, setMedicalDepartmentDetails] = useState([]);
  const fetchDoctorData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_doctor_list`,
        // Data payload
        {
          "start":0,
  "page_length":50
        },

        // Headers object
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
      console.log('list:', response.data.data.doctor_list);
      console.log('total list:', response.data.data.total_doctor);
      setDoctorDetails(response.data.data.doctor_list);
      setTotalDoctor(response.data.data.total_doctor);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchMedicalDepartmentList = async () => {
    try {
      setLoading(true);
      
      // Check if apiUrl and tokendata are defined
      if (!apiUrl || !tokendata || !tokendata.data) {
        console.error('API URL or token data is not defined');
        setLoading(false);
        return;
      }
      
      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_medical_department_list`,
        {}, // Empty body
        {
          headers: {
            "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        // console.log('Response Data:', response.data.data);
        setMedicalDepartmentDetails(response.data.data.department_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDoctorData();
    fetchMedicalDepartmentList();
  }, []);
  const [selectedGender, setSelectedGender] = useState("");

    return (
        <Layout>
          {loading ? <Loader /> : null}
            {/* md:max-w-6xl */}
            <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}} >
              <div className="flex items-center justify-between mb-4">
                              <h2 className="font-small text-[#8F8F8F]"><a  href="javascript:void(0);"
                                onClick={() => {
                                  nvg("/doctors");
                                }} className="no-underline hover:underline">Consultant List</a> &gt; New Consultant</h2>
              </div>
          

<div className="border-[1px] border-[#E2E8F0] bg-[#FFFFFF]">
   
<div className="px-[18px] pt-[15px] bg-[#FFFFFF] pb-[1px] border-[1px] border-[#FFFFFF] rounded-[2px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
  {/* Tabs Section */}
  <div class="relative w-full max-w-[504px] h-[35px] mt-[0px] ml-[0px]">
    <div class="flex flex-wrap gap-[10px]">

      <div className={`cursor-pointer min-w-[134px] h-[35px] p-[10px] rounded-[4px] flex items-center justify-center ${activeTab === 'tab1' ? 'bg-gradient-to-r from-[#280C79] via-[#280C79] to-[#FF7A45] text-[#FFFFFF]' : 'border-[0.7px] border-[#CDCDCD]'}`} style={{ backgroundPosition: '-15.3%' }} onClick={() => handleTabClick('tab1')}>
        <div class="text-[12px] leading-[14.52px] font-inter font-medium text-center">General Information</div>
      </div>

      <div className={`cursor-pointer min-w-[172px] h-[35px] p-[10px] rounded-[4px] flex items-center justify-center ${activeTab === 'tab2' ? 'bg-gradient-to-r from-[#280C79] via-[#280C79] to-[#FF7A45] text-[#FFFFFF]' : 'border-[0.7px] border-[#CDCDCD]'}`} style={{ backgroundPosition: '-15.3%' }} onClick={() => handleTabClick('tab2')}>
        <div class="text-[12px] leading-[14.52px] font-inter font-medium text-center">Employee and User Details</div>
      </div>

      <div className={`cursor-pointer min-w-[68px] h-[35px] p-[10px] rounded-[4px] flex items-center justify-center ${activeTab === 'tab3' ? 'bg-gradient-to-r from-[#280C79] via-[#280C79] to-[#FF7A45] text-[#FFFFFF]' : 'border-[0.7px] border-[#CDCDCD]'}`} style={{ backgroundPosition: '-15.3%' }} onClick={() => handleTabClick('tab3')}>
        <div class="text-[12px] leading-[14.52px] font-inter font-medium text-center">Charges</div>
      </div>

      <div className={`cursor-pointer min-w-[100px] h-[35px] p-[10px] rounded-[4px] flex items-center justify-center ${activeTab === 'tab4' ? 'bg-gradient-to-r from-[#280C79] via-[#280C79] to-[#FF7A45] text-[#FFFFFF]' : 'border-[0.7px] border-[#CDCDCD]'}`} style={{ backgroundPosition: '-15.3%' }} onClick={() => handleTabClick('tab4')}>
        <div class="text-[12px] leading-[14.52px] font-inter font-medium text-center">Appointments</div>
      </div>

    </div>
  </div>

  {/* Content Section */}
  {activeTab === 'tab1' && (
    <div className="grid gap-[10px] mt-14 md:grid-cols-3 sm:grid-cols-1 md:mt-[14px] mb-[14px]">
      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Consultant ID</label>
        <input type="text" className="w-full h-[40px] bg-[#F2F4F5] border border-[#DDE2E5] rounded-[4px] py-[8px] px-[16px]" placeholder="HLC-PRAC-.YYYY.-" disabled />
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Consultant Name</label>
        <input type="text" className="w-full h-[40px] bg-white border-[0.7px] border-[#656565] rounded-[4px] py-[8px] px-[16px]" placeholder="Consultant Name" />
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Gender</label>
          <select className={`w-full h-[40px] bg-white border-[0.7px] border-[#656565] rounded-[4px] 
          ${selectedGender ? 'text-black' : 'text-[#ABAFB1]'} 
          py-[8px] px-[16px]`}
          value={selectedGender}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedGender(value);
            // if (value !== '') {
            //   seterrorgender("");
            // }
          }}
          >
            <option value="">Select Gender</option>
            <option value="Male" className="text-[#2B2F32]">Male</option>
            <option value="Female" className="text-[#2B2F32]">Female</option>
            <option value="Other" className="text-[#2B2F32]">Other</option>
          </select>
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Consultant ID</label>
        <input type="text" className="w-full h-[40px] bg-[#F2F4F5] border border-[#DDE2E5] rounded-[4px] py-[8px] px-[16px]" placeholder="HLC-PRAC-.YYYY.-" disabled />
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Consultant Name</label>
        <input type="text" className="w-full h-[40px] bg-white border-[0.7px] border-[#656565] rounded-[4px] py-[8px] px-[16px]" placeholder="Consultant Name" />
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Gender</label>
        <select className={`w-full h-[40px] bg-white border-[0.7px] border-[#656565] rounded-[4px] 
          ${selectedGender ? 'text-black' : 'text-[#ABAFB1]'} 
          py-[8px] px-[16px]`}
          value={selectedGender}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedGender(value);
            // if (value !== '') {
            //   seterrorgender("");
            // }
          }}
          >
            <option value="">Select Gender</option>
            <option value="Male" className="text-[#2B2F32]">Male</option>
            <option value="Female" className="text-[#2B2F32]">Female</option>
            <option value="Other" className="text-[#2B2F32]">Other</option>
          </select>
      </div>
    </div>
    
  )}

{activeTab === 'tab2' && (
    <div className="grid gap-[10px] mt-14 md:grid-cols-3 sm:grid-cols-1 md:mt-[14px] mb-[14px]">
      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Consultant ID</label>
        <input type="text" className="w-full h-[40px] bg-[#F2F4F5] border border-[#DDE2E5] rounded-[4px] py-[8px] px-[16px]" placeholder="HLC-PRAC-.YYYY.-" disabled />
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Consultant Name</label>
        <input type="text" className="w-full h-[40px] bg-white border-[0.7px] border-[#656565] rounded-[4px] py-[8px] px-[16px]" placeholder="Consultant Name" />
      </div>

      <div className="w-full md:w-[354px] h-[65px]">
        <label for="company" className="block text-[#5E6366] font-Inter w-full h-[17px] mb-[8px] text-left px-[4px] gap-[4px]">Gender</label>
          <select className={`w-full h-[40px] bg-white border-[0.7px] border-[#656565] rounded-[4px] 
          ${selectedGender ? 'text-black' : 'text-[#ABAFB1]'} 
          py-[8px] px-[16px]`}
          value={selectedGender}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedGender(value);
            // if (value !== '') {
            //   seterrorgender("");
            // }
          }}
          >
            <option value="">Select Gender</option>
            <option value="Male" className="text-[#2B2F32]">Male</option>
            <option value="Female" className="text-[#2B2F32]">Female</option>
            <option value="Other" className="text-[#2B2F32]">Other</option>
          </select>
      </div>

     
    </div>
    
  )}
</div>

 </div>        



          </div>
         
        </Layout>
    );
};

export default NewDoctor;