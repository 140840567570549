  import './App.css';
  import {Route, Routes} from 'react-router-dom';
  import Header from "./components/Header";
  import Home from "./Pages/Home";
  import Dashboard from "./Pages/Dashboard";
  import Patient from "./Pages/Patient";
  import NewPatient from "./Pages/NewPatient";
  import EditPatient from "./Pages/EditPatient";
  import ViewPatient from "./Pages/ViewPatient";
  import Doctors from "./Pages/Doctors";
  import NewDoctor from "./Pages/NewDoctor";
  import EditDoctor from "./Pages/EditDoctor";
  import ViewDoctor from "./Pages/ViewDoctor";
  import Appointment from "./Pages/Appointment";
  import NewAppointment from "./Pages/NewAppointment";
  import EditAppointment from "./Pages/EditAppointment";
  import ViewAppointment from "./Pages/ViewAppointment";
  import PatientEncounter from "./Pages/PatientEncounter";
  import NewPatientEncounter from "./Pages/NewPatientEncounter";
  import EditPatientEncounter from "./Pages/EditPatientEncounter";
  import ViewPatientEncounter from "./Pages/ViewPatientEncounter";
  import Payment from "./Pages/Payment";
  import NewSchedule from "./Pages/NewSchedule";
  import Department from "./Pages/Department";
  import NewDepartment from "./Pages/NewDepartment";
  import { useEffect, useRef, useState } from "react";
  import { getToken, removeToken } from "./services/LocalStorageSrv";
  import { useNavigate,useLocation  } from "react-router-dom";
  const App = () => {
    const location = useLocation();
    
    if (location.pathname === '/') {
      // console.log('test:','home');
    return (

      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
  );
    }else{
      // console.log('test1:','about');
      return (
      <div className="App">
        
        <Header />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/patient" element={<Patient />} />
          <Route path="/new-patient" element={<NewPatient />} />
          <Route path="/edit-patient/:patient_id" element={<EditPatient />} />
          <Route path="/view-patient" element={<ViewPatient />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/new-doctor" element={<NewDoctor />} />
          <Route path="/edit-doctor/:id" element={<EditDoctor />} />
          <Route path="/view-doctor" element={<ViewDoctor />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/new-appointment" element={<NewAppointment />} />
          <Route path="/edit-appointment/:id" element={<EditAppointment />} />
          <Route path="/view-appointment" element={<ViewAppointment />} />
          <Route path="/patientencounters" element={<PatientEncounter />} />
          <Route path="/new-patientencounter" element={<NewPatientEncounter />} />
          <Route path="/edit-patientencounter" element={<EditPatientEncounter />} />
          <Route path="/view-patientencounter" element={<ViewPatientEncounter />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/new-schedule" element={<NewSchedule />} />
          <Route path="/department" element={<Department />} />
          <Route path="/new-department" element={<NewDepartment />} />
        </Routes>
      </div>
      );
    }
};

  export default App;
