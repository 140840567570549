import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { getToken, removeToken, storeToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Select, { components } from 'react-select';
import { MdErrorOutline } from 'react-icons/md';  // Importing MdErrorOutline from react-icons
import Loader from "../Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewDepartment = () => {
   
	
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  // console.log('print key:',tokendata.data.api_key);
  const nvg = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setsuccess] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue1, setSelectedValue1] = useState('');
  const [patientCareType, setPatientCareType] = useState([]);
  const [showModal, setShowModal] = useState(false); 

  
  const fetchPatientCareType = async () => {
    try {
      setLoading(true);
    
      const response = await axios.get(
        `${apiUrl}/healthcare_management_system.web.api.get_patient_care_type`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${tokendata.data.api_key.trim()}:${tokendata.data.api_secret.trim()}`,
          },
        }
      );

      if (response.data && response.data.data) {
        // console.log('care type list:', response.data.data.patient_care_type_list);
        setPatientCareType(response.data.data.patient_care_type_list);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPatientCareType();
  }, []);
  const customStylesD = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      borderColor: '#656565',
      padding: '2.5px',
      width: '100%',
      fontSize: '0.875rem', // text-sm
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.data.color ? state.data.color : state.isSelected ? '#2B2F32' : '#2B2F32',
      backgroundColor: state.isSelected ? '#F0F0F0' : '#FFFFFF',
      textAlign: 'left', // Align text to the left
      '&:hover': {
        backgroundColor: '#f0f0f0', // Light gray hover color
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: 'left', // Align placeholder to the left
    }),
  };
  
  const options = [
    ...patientCareType.map((sts) => ({
      value: sts.patient_care_type,
      label: sts.patient_care_type,
    })),
    { value: 'create-patient-care-type', label: '+ Create Patient Care Type', color: '#EC642A' },
  ];
  const [errordelectedvalue1, seterrordelectedvalue1] = useState("");
  const [errordelectedvalue, seterrordelectedvalue] = useState("");
  const savedepartment = async () => {
    if (selectedValue1 !== "" && selectedValue !== "") {
      try {
        setLoading(true);
        const formdata = {
          department_id: '',
          department: selectedValue1,
          patient_care_type: selectedValue,
        };
        const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.create_department`,
          formdata,
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
  
        if (response.data.message === "Medical Department created successfully") {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
          });
  
            setTimeout(() => {
              setLoading(false); // Stop loading right before the page refresh
              // window.location.reload();
              nvg('/department'); // Redirect to the department list page
            }, 2000);
        } else {
          setLoading(false); // In case the message is not successful
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading on error
      }
    } else {
      if (selectedValue1 === "") {
        seterrordelectedvalue1("Department is Required");
      } else {
        seterrordelectedvalue1("");
      }
      if (selectedValue === "") {
        seterrordelectedvalue("Patient Care Type is Required");
      } else {
        seterrordelectedvalue("");
      }
    }
  };
  
  const [patientcaretype, setpatientcaretype] = useState("");
  const [errorpatientcaretype, seterrorpatientcaretype] = useState("");
  const handlePatientCareSave = async () => {
    if (patientcaretype !== "") {
      try {
        setLoading(true);
        const formdata = {
          patient_care_type: patientcaretype,
        };
        const response = await axios.post(
          `${apiUrl}/healthcare_management_system.web.api.create_patient_care_type`,
          formdata,
          {
            headers: {
              "Authorization": `token ${tokendata.data.api_key}:${tokendata.data.api_secret}`,
            },
          }
        );
        
        if (response.data.message === "Patient Care Type created successfully") {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
          });
  
            setTimeout(() => {
              setLoading(false); // Stop loading right before the page refresh
              window.location.reload();
              // nvg('/new-department'); // Redirect to the department list page
            }, 2000);
        } else {
          setLoading(false); // In case the message is not successful
        }
      } catch (error) {
        alert('Check Duplicate Entry of Patient Care Type.')
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading on error
      }
    } else {
      if (patientcaretype === "") {
        seterrorpatientcaretype("Department is Required");
      } else {
        seterrorpatientcaretype("");
      }
     
    }
  };
  
    return (
        <Layout>
          {loading ? <Loader /> : null}
          <ToastContainer />
            {/* md:max-w-6xl */}
   
          <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9',height:'100%'}}>

                <div class="flex items-center justify-between mb-4">
                <h2 class="font-small text-[#8F8F8F]"><a  href="javascript:void(0);"
                      onClick={() => {
                        nvg("/department");
                      }} className="no-underline hover:underline">Department List</a> &gt; New Department</h2>
              </div>

              <div className="border-[1px] border-[#E2E8F0] bg-[#FFFFFF]">
   
   <div className="px-[18px] pt-[15px] bg-[#FFFFFF] pb-[1px] border-[1px] border-[#FFFFFF] rounded-[2px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
       
      <div className="grid gap-3 mb-6 md:grid-cols-3">

      <div>
        <label for="appointment_type" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Department</label>
        <input type="text" className="bg-[#FFFFFF] border focus:outline-none border-gray-300 text-sm  focus:ring-blue-500 focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#ABAFB1] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-black rounded-[4px]" placeholder="Department"  value={selectedValue1}
      onChange={(e) => {
        const value = e.target.value;
        setSelectedValue1(value);
        if (value !== '') {
          seterrordelectedvalue1("");
        }
      }}
      style={{
        border: errordelectedvalue1 ? '1px solid red' : '',  // Apply red border if error
      }}
      />
    </div>
    <div>
      <label for="company" className="text-left block text-[#5E6366] font-Mulish w-[354px] h-[17px] px-[4px] gap-[10px] mb-[10px]">Patient Care Type</label>
          <Select
        value={options.find(option => option.value === selectedValue)}
        onChange={(selectedOption) => {
          const selectedValue = selectedOption.value;
          setSelectedValue(selectedValue);
          if (selectedValue === "create-patient-care-type") {
            setShowModal(true); // Show modal instead of navigating
          }
        }}
        options={options}
        placeholder="Select Patient Care Type"
        styles={customStylesD}
        isSearchable
      />
      {/* Modal Component */}
     {showModal && (
        
        <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="relative bg-white rounded-lg w-small max-w-2xl p-2 md:p-4">
            <div className='flex items-center justify-between'>
              <h5 className='text-[16px] text-[#1E1E1E] font-inter font-semibold text-base leading-19.36'>New Patient Care Type</h5>
              <button
                className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={() => setShowModal(false)} // Close button onClick handler
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
        
<div className="px-[1px] pt-[4px] bg-[#F8F9FC] pb-[0px]">
 <div className="grid gap-2 mb-6 md:grid-cols-1">

 
  <div>
        <label for="company" className="text-left block text-[#5E6366] font-Inter w-[354px] h-[17px] px-[1px] gap-[5px] mb-[10px]">Patient Care Type</label>
        <input type="text" className="bg-[#F2F4F5] border border-gray-300 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#656565] text-[#5E6366] dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-[4px]" placeholder="Patient Care Type" value={patientcaretype}
                onChange={(e) => {
                  setpatientcaretype(e.target.value);
                  if (e.target.value) {
                    seterrorpatientcaretype(''); // Clear the error when a date is selected
                  }
                }} 
                style={{
                  border: errorpatientcaretype ? '1px solid red' : '',  // Apply red border if error
                }}  />
  </div>
 
 
</div>




</div>



<div className="flex justify-between items-center space-x-4">

<button
  type="submit"
  onClick={handlePatientCareSave}
  className="text-white bg-[#FC8B5C] font-medium text-sm px-8 w-[120px] h-[38px] rounded-[12px] font-inter"
>
  Save
</button>
</div>



          </div>
        </div>
      </div>
      )}  
    </div>
</div>


<div class="flex justify-end w-full">
  <button 
    type="submit" 
    onClick={savedepartment}
    class="text-white bg-[#823455] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-[180px] h-[44px] rounded-[12px]">
    Save
  </button>
</div>
<br/>

</div>
 </div>        


          </div>
     
        </Layout>
    );
};

export default NewDepartment;