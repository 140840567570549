import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from '../components/Layout';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa'; 
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
const Department = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const tokendata = getToken("data");
  const nvg = useNavigate();
  const [loading, setLoading] = useState(false);
  const [patient_encounterDetails, setPatientEncounterDetails] = useState([]);
  const [displayedPatients, setDisplayedPatients] = useState([]);
  const [totalPatient_encounter, setTotalPatientEncounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const batchSize = 10000; // Fetch 60 records from the API
  const patientsPerPage = 10; // Display 10 records per page

  const fetchPatientEncounterData = async (page) => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${apiUrl}/healthcare_management_system.web.api.get_medical_department_list`,
        {
          start: 0,
          page_length: batchSize,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${tokendata.data.api_key.trim()}:${tokendata.data.api_secret.trim()}`,
          },
        }
      );

      if (response.data && response.data.data) {
        console.log('department:',)
        setPatientEncounterDetails(response.data.data.department_list);
        setTotalPatientEncounter(response.data.data.total_departments);
        paginateData(response.data.data.total_departments, 0);
      } else {
        console.error('Unexpected response structure:', response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const paginateData = (data, selectedPage) => {
    const offset = selectedPage * patientsPerPage;
    const paginatedData = data.slice(offset, offset + patientsPerPage);
    setDisplayedPatients(paginatedData);
  };

  useEffect(() => {
    fetchPatientEncounterData();
  }, []);

  useEffect(() => {
    paginateData(patient_encounterDetails, currentPage);
  }, [currentPage, patient_encounterDetails]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <Layout>
       {loading ? <Loader /> : null}
      <div className="md:mx-auto px-4 py-4" style={{backgroundColor: '#F1F5F9', height:'100%'}}>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-sm text-[#8F8F8F]">Department List</h2>
        </div>

        <div className="bg-[#fff]">
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className='px-[13px] pt-[15px] bg-[#F8F9FC] pb-[15px]'>
              <div className='flex items-center justify-between'>
                <h1 className='text-xl font-bold text-gray-800'>Department</h1>
                <a href="javascript:void(0);" onClick={() => nvg("/new-department")} className='bg-[#823455] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[8px]'>+ Add Department</a>
              </div>
            </div>

            <div className="grid grid-cols-6 border-t border-stroke px-4 py-4.5 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5" style={{height:'50px'}}>
              <div className="col-span-1 flex items-center">
                <p className="font-medium text-[#64748B]">Sl No.</p>
              </div>
              <div className="col-span-3 flex items-center">
                <p className="font-medium text-[#64748B]">Department</p>
              </div>
              <div className="col-span-2 flex items-center">
                <p className="font-medium text-[#64748B]">Patient Care Type</p>
              </div>
              
              
              <div className="col-span-2 flex items-center">
                <p className="font-medium text-[#64748B]">Id</p>
              </div>
            </div>
            
            {displayedPatients.length > 0 ? (
              displayedPatients.map((list, index) => (
                <div key={list.id} className="grid grid-cols-8 border-t border-stroke px-4 py-4.5 dark:border-strokedark" style={{height:'40px', gridTemplateColumns: 'repeat(8, 1fr)'}}>
                  <div className="col-span-1 flex items-center">
                    <p className="text-sm font-Mulish text-[#1C2434] dark:text-white ml-4">{(currentPage * patientsPerPage) + index + 1}</p>
                  </div>
                  <div className="col-span-3 flex items-center">
                    <p className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.department}</p>
                  </div>
                  <div className="col-span-2 flex items-center">
                    <p className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.department}</p>
                  </div>
                  <div className="col-span-2 flex items-center">
                    <p className="text-sm font-Mulish text-[#1C2434] dark:text-white">{list.department}</p>
                  </div>
                
                  
                </div>
              ))
            ) : (
              <div className="text-center py-4">
                Department Data Not Available.
              </div>
            )} 

             {displayedPatients.length > 0 && (
              <div className="grid grid-cols-12 items-center px-4 py-6 md:px-6 xl:px-7.5">
                <div className="col-span-4 text-left">
                  <h6 className="text-sm font-small text-[#64748B] dark:text-white">Showing {currentPage * patientsPerPage + 1} to {Math.min((currentPage + 1) * patientsPerPage, totalPatient_encounter)} of {totalPatient_encounter} entries</h6>
                </div>
                <div className="col-span-8 text-right">
                  
                <ReactPaginate
  pageCount={Math.ceil(totalPatient_encounter / patientsPerPage)}
  pageRangeDisplayed={3}
  marginPagesDisplayed={2}
  onPageChange={handlePageChange}
  containerClassName="pagination-container"
  pageClassName="pagination-item"
  pageLinkClassName="pagination-link"
  activeClassName="pagination-active"
  previousClassName="pagination-prev"
  nextClassName="pagination-next"
  previousLinkClassName="pagination-link"
  nextLinkClassName="pagination-link"
  previousLabel={<img src="/images/Expand_left_double.png" alt="Previous" />}
  nextLabel={<img src="/images/Expand_right_double.png" alt="Next" />}
  breakLabel="..."
/>
                </div>
              </div>
            )}
          </div>
        </div>        
      </div>
    </Layout>
  );
};

export default Department;